import React, {Component} from 'react';

import CTA from './CTA';

class Error extends Component {
  componentDidMount() {
    document.querySelector('nav a:first-child').focus();
    document.querySelector('nav a:first-child').blur();

    // menu
    document.querySelectorAll('nav a').forEach(navlink => navlink.classList.remove('active-link'));
    document.querySelector('nav').classList.remove('menuActive');
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="jumbotron jumbotron-fluid content-wrapper" id="error">
        <div className="pt-3 d-flex align-items-center justify-content-center error-wrapper">
          <div className="container d-flex flex-column align-items-center justify-content-around px-md-5">
            <div className="mb-5 text-center">

              <div className="d-flex flex-column align-items-center justify-content-between mb-3">
                <h2 className="text-center">error()</h2>
              </div>
              <p>Not Found</p>
            </div>
            <div className="my-5">
              <CTA to="/work">{'<<'} back to my work</CTA>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Error;